<div class="modal-header">
    <h4 class="modal-title">CREATE CATEGORY</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="inputfield">
        <label>Enter the category name:</label>
        <input type="text" [(ngModel)]="category" required focus class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" />
        <p *ngIf="alertText" style="color: red;">{{alertText}}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="accept()">Save</button>
</div>