<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
    <div class="inputfield">
        <label>Enter the treatment name:</label>
        <input type="text" [(ngModel)]="treatment" required focus class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" />
        <p *ngIf="alertText" style="color: red;">{{alertText}}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="accept()">Save</button>
</div>