import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposerComponent } from './proposer.component';
import { BottombarComponent } from './components/bottombar/bottombar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { CanvasComponent } from './components/image-editor/canvas/canvas.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { UnitInputPipe } from './pipes/unit-pipe/unit-input.pipe';
import { MlInputPipe } from './pipes/ml-pipe/ml-input.pipe';
import { TreatmentBarComponent } from './components/image-editor/treatment-bar/treatment-bar.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProposerRoutingModule } from './proposer-routing.module';
import { ConfirmComponent } from './components/modals/confirm-dialog/confirm-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CreateProductModalComponent } from './components/modals/create-product/create-product-modal.component';
import { ModalService } from '../shared/services/modal.service';
import { CreateCategoryComponent } from './components/modals/create-category/create-category.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { CreateTreatmentComponent } from './components/modals/create-treatment/create-treatment.component';
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { ColorPickerModule } from 'ngx-color-picker';
import { FaceSimulationComponent } from './components/face-simulation/face-simulation.component';
import { FaceSimulationService } from './services/face-simulation.service';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    ProposerComponent,
    BottombarComponent,
    TopbarComponent,
    CanvasComponent,
    ImageEditorComponent,
    CanvasComponent,
    RecommendationComponent,
    UnitInputPipe,
    MlInputPipe,
    TreatmentBarComponent,
    ConfirmComponent,
    CreateProductModalComponent,
    CreateCategoryComponent,
    AlertModalComponent,
    CreateTreatmentComponent,
    FaceSimulationComponent
  ],
  imports: [
    CommonModule,
    ProposerRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule,
    MatSliderModule,
    ColorPickerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [UnitInputPipe, MlInputPipe, ModalService, FaceSimulationService],
  exports: [ProposerComponent]
})
export class ProposerModule { }
