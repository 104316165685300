import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { DataService, Treatment } from 'src/app/shared/services/data.service';
import { Assessment, ImageService } from 'src/app/shared/services/image.service';
import { ModalService } from 'src/app/shared/services/modal.service';


@Component({
  selector: 'app-treatment-bar',
  templateUrl: './treatment-bar.component.html',
  styleUrls: ['./treatment-bar.component.css'],
})
export class TreatmentBarComponent implements OnInit {

  // properties
  constructor(private _modal: ModalService, private _dataService: DataService, private _spinner: NgxSpinnerService, private _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._uuid = this._route.snapshot.queryParams['uuid']
    this._getTreatments()
  }

  // properties
  treatmentList: Treatment[] = [];
  isTreatmentEditorShown = false;
  // event method
  onAddTreatment() {
    this._modal.openTreatmentForm('CREATE')
      .then(res => {
        if(res) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.postTreatment(res))
            .then(t => {
              this._modal.openNotification('Created treatment')
              this.treatmentList.push(t)
            }).catch(err => {
              this._modal.openNotification(err.status == 422? 'Duplicated name!':'Failed create new treatment!')
            }).finally(() => {
              this._spinner.hide('tm-spinner')
            })
        }
      })
      .catch(err => {
      })
      .finally(() => {
      })
  }

  onClickTreatment(ev: Event, treatment: Treatment) {
    this._modal.openTreatmentForm('UPDATE', treatment.name)
      .then(res => {
        if(res) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.putTreatment(treatment._id, res))
            .then(() => {
              treatment.name = res
              this._modal.openNotification('Successfully updated')
            }).catch((err) => {
              this._modal.openNotification('Failed to update')
            }).finally(() => {
              this._spinner.hide('tm-spinner')
            });
        }
      })
  }

  onDelTreatment(ev: Event, treatment: Treatment) {
    ev.stopPropagation()
    this._modal.openConfirm('CONFIRM', `Do you really want to remove this assessment from the image?`)
      .then((result) => {
        if(result) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.delTreatment(treatment))
          .then((res) => {
            this.treatmentList = this.treatmentList.filter(t => t._id != treatment._id)
            this._modal.openNotification('Successfully disabled treatment')
          }).catch((err) => {
            this._modal.openNotification('Failed to disable treatment')
          }).finally(() => {
            this._spinner.hide('tm-spinner')
          });
        }
      }).catch((err) => {
        
      });
  }

  onTreatmentDragStart(ev: any, value: string): void {
    ev.dataTransfer.dropEffect = "move";
    ev.dataTransfer.effectAllowed = "move";
    ev.dataTransfer.setData('text', value);
  }

  onToggleTreatmentEditor(): void {
    this.isTreatmentEditorShown = !this.isTreatmentEditorShown;
  }

  refresh() {
    this._getTreatments()
  }
  // private
  private _uuid!: string

  private _getTreatments() {
    this._spinner.show('tm-spinner')
    lastValueFrom(this._dataService.getTreatments())
      .then((result) => {
        this.treatmentList = [...result]
      }).catch((err) => {
        this._modal.openNotification(err.message)
      }).finally(() => {
        this._spinner.hide('tm-spinner')
      })
  }
}
