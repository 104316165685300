import { Component, EventEmitter, Output, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Product } from '../../models/product';
import { ProductCategory } from '../../models/product-category';
import { REPORT_LAYOUTS } from 'src/app/utils/items';
import { DataService } from '../../../shared/services/data.service';
import { firstValueFrom, last, lastValueFrom, windowCount } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

import { HubImage, ImageService, Patient } from '../../../shared/services/image.service';
import { ModalService } from '../../../shared/services/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['../image-editor/image-editor.component.css', './recommendation.component.css']
})
export class RecommendationComponent implements OnInit {

  categoryList: ProductCategory[] = [];
  categoryMap = new Map<string, ProductCategory>()
  productList: Product[] = []
  reportLayouts = REPORT_LAYOUTS
  reviewingProduct!: Product | null
  isProductEditing: boolean = false
  isShowDefault: boolean = true
  patient!: Patient
  isProductProcessing: boolean = false

  @Input() imageData!: string
  @Input() isPrint!: boolean
  @Input() image!: HubImage

  @Output() isPrintChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private dataService: DataService, private _spinner: NgxSpinnerService, private _modalService: ModalService, private _imageService: ImageService) {
  }

  ngOnInit(): void {
    this._getProducts();
  }


  // Private methods

  // Methods

  // Event
  onToggleShowDefault() {
    this.isShowDefault = !this.isShowDefault
  }

  async onCreateReport(event: Event, index: number) {
    try {
      this._spinner.show('report-spinner');
      if(!this.image || !this.image.patient_uuid) {
        console.log('No patient')
      } else {
        try {
          this.patient = await lastValueFrom(this._imageService.getPatient(this.image.patient_uuid))
        } catch (err) {}
      }
      let products: string[] = [];
      this.productList.forEach(p => {
        if(p.selected) products.push(p._id)
      })
      let blob = await (await fetch(this.imageData)).blob();
      let file: File = new File([blob], 'reportImage', {type: blob.type});
      const res = await firstValueFrom(this.dataService.exportReport(file, products, {...this.patient}, index))
      const w = window.open(`/pdf?uuid=${res.uuid}`)
      if(!w) {
        this._modalService.openNotification('Pop-ups are blocked. Please allow pop-ups from this domain to preview generated reports!')
      }
    } catch (err) {
      this._modalService.openNotification('Failed to create report')
    } finally {
      this._spinner.hide('report-spinner')
    }
  }

  onToggleEditingMode() {
    this.isProductEditing = !this.isProductEditing
  }

  onPrintClosed() {
    this.isPrintChange.emit(false);
  }

  onPreviewImageLoaded(event: Event) {
    event.preventDefault();
    this._spinner.hide('review-spinner')
  }

  onProductClicked(ev: Event, product: Product) {
    if(this.isProductEditing) {
      this._modalService.openNotification('You are in edit mode')
    } else {
      this.onProductReviewed(ev, product)
    }
  }

  onProductReviewed(event: Event, product: Product) {
    if(this.reviewingProduct?._id == product._id) {
      return
    }
    this._spinner.show('review-spinner')
    this.reviewingProduct = product;
  }
  

  refreshComponent() {
    if(this.isProductProcessing) return
    this._getProducts()
  }

  onCreateCategory() {
    this._modalService.openCategoryForm()
      .then(result => {
        if(result) {
          if(this.categoryMap.has(result)) {
            this._modalService.openNotification('The category has existed!')
          } else {
            let c = new ProductCategory(result)
            this.categoryList.push(c)
            this.categoryMap.set(c.category, c)
          }
        }
      })
  }

  onCreateProduct(event: Event, category: string) {
    this._modalService.openProductForm('CREATE PRODUCT', category)
      .then(result => {
        if(result) {
          this._modalService.openNotification('Product created')
          let p = result as Product
          this.productList.push(p)
          this._refreshReview()
          this._arrangeProducts()
        }
      })
  }

  onEditCategory(ev: Event, category: ProductCategory) {
    this._modalService.openConfirm('CONFIRM', 'Default products will not be updated! Continue?')
      .then(() => {
        this._modalService.openCategoryForm(category.category)
        .then((result) => {
          if(result && result != category.category) {
            this._spinner.show('product-spinner')
            lastValueFrom(this.dataService.putCategory(category.category, result))
              .then(() => {
                this.categoryMap.get(category.category)?.products.forEach(p => { if(!p.isDefault) p.category == result })
                this._arrangeProducts()
                this._modalService.openNotification('Successfully edit category')
              }).catch((err) => {
                this._modalService.openNotification('Failed to edit category')
              }).finally(() => {
                this._spinner.hide('product-spinner')
              })
          }
        }).catch((err) => {
          
        });
      })
      .catch()
  }

  onEditProduct(event: Event, product: Product) {
    this._modalService.openProductForm('EDIT PRODUCT', undefined, product)
      .then(result => {
        if(result) {
          this._spinner.show('product-spinner')
          this._modalService.openNotification('Product edited')
          let p = result as Product
          product.category = p.category
          product.name = p.name
          product.description = p.description
          product.price1 = p.price1
          product.price1desc = p.price1desc
          product.price2 = p.price2
          product.price2desc = p.price2desc
          product.isDefault = p.isDefault
          if(p.url) product.url = p.url
          this._arrangeProducts()
          this._refreshReview()
          this._spinner.hide('product-spinner')
        }
      })
  }

  onProductTypeSelected(event: MouseEvent, category: ProductCategory) {
    this.categoryList.forEach(c => {
      if(c.category == category.category) 
        c.selected = !c.selected
      else c.selected = false
    })

  }

  onProductSelected(event: MouseEvent, product: Product) {
    product.selected = true
  }

  onProductDeselected(event: MouseEvent, product: Product) {
    product.selected = false 
  }

  deleteCategory(_event: MouseEvent, data: ProductCategory) {
    _event.stopPropagation();
    if(!data.products || data.products.length < 1) {
      this.categoryMap.delete(data.category)
      this.categoryList = this.categoryList.filter(c => c.category != data.category)
      this._modalService.openNotification('Category deleted')
    } else {
      this._modalService.openConfirm('CONFIRM', 'Do you want to delete this category?', 'Yes', 'Cancel')
        .then(result => {
          if (result) {
            this._spinner.show('product-spinner')
            lastValueFrom(this.dataService.deleteCategory(data.category))
              .then(resp => {
                this.productList = this.productList.filter(p => p.category != data.category)
                this._refreshReview()
                this._modalService.openNotification('Category deleted')
                this._spinner.hide('product-spinner')
              }).catch(err => {
                this._spinner.hide('product-spinner')
              })
          }
        })
    }
  }

  deleteProduct(_event: MouseEvent, data: Product) {
    _event.stopPropagation();
    this._modalService.openConfirm('CONFIRM', 'Do you want to delete the product?', 'Yes', 'No')
      .then(result => {
        if(result) {
          this.isProductProcessing = true
          this._spinner.show('product-spinner')
          lastValueFrom(this.dataService.deleteProduct(data._id))
            .then(resp => {
              this.productList = this.productList.filter(p => p._id != data._id)
              this._arrangeProducts()
              this._spinner.hide('product-spinner')
              this._modalService.openNotification('Product deleted')
              this._refreshReview()
            }).catch(err => {
              this._spinner.hide('product-spinner')
            }).finally(() => {
              this.isProductProcessing = false
            })
        }
      })
  }

  private _refreshReview() {
    this.reviewingProduct = null
  }

  private _getProducts() {
    this.isProductProcessing = true
    this._spinner.show('product-spinner')
    lastValueFrom(this.dataService.getProducts())
      .then(res => {
        this.productList = res.payload.products
        this._arrangeProducts()
        this._spinner.hide('product-spinner')
      })
      .catch(err => {
        console.log(err)
        this._spinner.hide('product-spinner')
      }).finally(() => {
        this.isProductProcessing = false
      })
  }

  private _arrangeProducts() {
    this.categoryList = []
    this.categoryMap.clear()
    this.productList.forEach(p => {
      if(!this.categoryMap.has(p.category)) {
        let c = new ProductCategory(p.category);
        this.categoryList.push(c)
        this.categoryMap.set(p.category, c);
      }
      this.categoryMap.get(p.category)?.products.push(p)
    })
    this.categoryList.forEach(c => {
      c.products.sort((a, b) => a.name > b.name? 1: -1)
    })
  }
}

