export const TOOLBAR_ITEMS = [
    [
        {
            name: "Pointer",
            class: "fa fa-solid fa-arrow-pointer",
            isActive: false,
            tooltip: 'Select single drawing'
        },
        {
            name: "Rectangle",
            class: "fa fa-square-o",
            isActive: false,
            tooltip: 'Select multiple drawings'
        },
    ],
    [
        {
            name: "Highlight",
            class: "fa-solid fa-highlighter",
            isActive: false,
            tooltip: 'Highlight tool'
        },
        {
            name: "Arrow",
            class: "fa-solid fa-arrow-pointer fa-flip-horizontal",
            isActive: false,
            tooltip: 'Arrow tool'
        },
        {
            name: "Free Line",
            class: "fa-sharp fa-solid fa-pen",
            isActive: false,
            tooltip: 'Freehand drawing tool'
        },
        {
            name: "Area",
            class: "fa fa-square-o",
            isActive: false,
            tooltip: 'Area drawing tool'
        },
        {
            name: "Black Bar",
            class: "fa-sharp fa-solid fa-square black",
            isActive: false,
            tooltip: 'Tool to create "blacked out" rectangles'
        },        
        {
            name: "Black Circles",
            class: "fa-solid fa-circle",
            isActive: false,
            tooltip: 'Tool to create "blacked out" ellipses'
        },
        {
            name: "Crop",
            class: "fa-solid fa-crop",
            isActive: false,
            tooltip: 'Image crop tool'
        },
        {
            name: "Text",
            class: "fa fa-text-height",
            isActive: false,
            tooltip: 'Simple text tool'
        }
    ],
    [
        {
            name: "Point",
            class: "fa-solid fa-circle-dot",
            isActive: false,
            tooltip: 'Draw points with unit/ml - values'
        },
        {
            name: "Cross",
            class: "fa-sharp fa-solid fa-plus",
            isActive: false,
            tooltip: 'Draw crosses with unit/ml - values'
        },
        {
            name: "Move",
            class: "fa-solid fa-up-down-left-right",
            isActive: false,
            tooltip: 'Move background when zoomed'
        }
    ]
];

export const MODE_BUTTONS = [
    {
        name: 'Original',
        isActive: true
    },
    {
        name: 'Pigmentation',
        isActive: false
    },
    {
        name: 'Redness',
        isActive: false
    },
    {
        name: 'Evenness',
        isActive: false
    }
]

export const LINE_SIZES = [
    {
        fontSize: 42,
        active: 1,
        width: 10
    },
    {
        fontSize: 36,
        active: 0,
        width: 8
    },
    {
        fontSize: 30,
        active: 0,
        width: 6
    },
    {
        fontSize: 24,
        active: 0,
        width: 4
    },
    {
        fontSize: 18,
        active: 0,
        width: 2
    }
]

export const FONT_SIZES = [
    {
        fontSize: 42,
        active: 1,
        size: 50
    },
    {
        fontSize: 36,
        active: 0,
        size: 42
    },
    {
        fontSize: 30,
        active: 0,
        size: 34
    },
    {
        fontSize: 24,
        active: 0,
        size: 26
    },
    {
        fontSize: 18,
        active: 0,
        size: 18
    }
]

export const RADIUS_OPTIONS = [
    {
        fontSize: 42,
        active: 1,
        width: 10
    },
    {
        fontSize: 36,
        active: 0,
        width: 8
    },
    {
        fontSize: 30,
        active: 0,
        width: 6
    },
    {
        fontSize: 24,
        active: 0,
        width: 4
    },
    {
        fontSize: 18,
        active: 0,
        width: 2
    }
]

export const HIGHLIGHT_SIZES = [
    {
        fontSize: 42,
        active: 1,
        width: 60
    },
    {
        fontSize: 34,
        active: 0,
        width: 50
    },
    {
        fontSize: 26,
        active: 0,
        width: 40
    },
    {
        fontSize: 18,
        active: 0,
        width: 30
    },
    {
        fontSize: 10,
        active: 0,
        width: 20
    }
]

export const HIGHLIGHT_OPACITY = [
    {
        opacity: 1,
        active: 1
    },
    {
        opacity: 0.8,
        active: 0
    },
    {
        opacity: 0.6,
        active: 0
    },
    {
        opacity: 0.4,
        active: 0
    },
    {
        opacity: 0.2,
        active: 0
    }
]

export const COLOR_PICKER = [
    {
        color: '#8CC141',
        active: 1
    },
    {
        color: '#00FFFF',
        active: 0
    },
    {
        color: '#0000FF',
        active: 0
    },
    {
        color: '#FF65CB',
        active: 0
    },
    {
        color: '#FFFF00',
        active: 0
    },
    {
        color: '#DE00FF',
        active: 0
    },
    {
        color: '#FF0000',
        active: 0
    },
    {
        color: '#893681',
        active: 0
    },
];

export const PRODUCTS = [
    {
        type: 'Acne',
        selected: false,
        data: [
            {
                name: 'Benzac AC',
                desc: 'Benzac AC is a water based benzoyl peroxide treatment for mild to moderate acne. It absorbs excess oil, kills bacteria and does not excessively dry skin.',
                path: 'benzac_tester.jpg',  
                selected: false
            },
            {
                name: 'Blemishes no more intensolution',
                desc: 'Blemishes no more intensolution is a intense, maximum-strength all-in-one acne treatment with Dr.Brandt\'s ACTIVE IMPURITY SHIELD.',
                path: 'nomoreintensolution_drbrandt.jpg', 
                selected: false
            },
        ]
    },
    {
        type: 'Anti-Oxidants',
        selected: false,
        data: [
            {
                name: 'ANTI-OXIDANT BOOST',
                desc: 'ANTI-OXIDANT BOOST with Supplamine combines the power of Supplamine with Indian gooseberry and grape seed extract to provide the ultimate therapy that your skin cells need to fight glycation, free radicals, and oxidative stress.',
                path: 'facial_treatment.jpg', 
                selected: false
            },
            {
                name: 'CE Ferulic Serum',
                desc: 'CE Ferulic is an antioxidant combination that delivers advanced protection against photoaging by neutralizing free radicals, boosting collagen synthesis, and providing unmatched antioxidant protection.',
                path: 'skinceuticals_ce.jpg', 
                selected: false
            },
        ]
    },
];

export const REPORT_LAYOUTS = [
    {
        path: '0.lst.png',
        tooltip: 'Landscape detail layout - best fit for portrait images'
    },
    {
        path: '1.lst.png',
        tooltip: 'Landscape overview layout - best fit for portrait images'
    },
    {
        path: '2.lst.png',
        tooltip: 'Portrait detail layout - best fit for landscape images'
    },
    {
        path: '3.lst.png',
        tooltip: 'Portrait detail layout - best fit for landscape images'
    },
]