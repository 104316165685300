<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="inputfield">
        <label>Category:</label>
        <input type="text" class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" [(ngModel)]="category" [disabled]="isProcessing" />
    </div>
    <div class="inputfield">
        <label>Name (required):</label>
        <input type="text" class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" [(ngModel)]="name" autofocus required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>Description:</label>
        <textarea class="border border-ffgray2 focus:outline-none bg-ffblack h-20 p-2 rounded-lg" [(ngModel)]="description" [disabled]="isProcessing"></textarea>
    </div>
    <div class="inputfield">
        <label>Price 1:</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="number" [(ngModel)]="price1" required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>Price 1 Description:</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="text" [(ngModel)]="price1desc" [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>Price 2:</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="number" [(ngModel)]="price2" required [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>Price 2 Description:</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="text" [(ngModel)]="price2desc" [disabled]="isProcessing"/>
    </div>
    <div class="inputfield">
        <label>Image (required):</label>
        <input class="border border-ffgray2 focus:outline-none bg-ffblack h-10 p-2 rounded-lg" type="file" accept="image/*" [disabled]="isProcessing" (change)="onImageSelected($event)"/>
    </div>
    <p *ngIf="alert" style="color: red;">{{alert}}</p>
</div>
<div class="modal-footer" style="position: relative;">
    <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="modal-spinner" size="medium" type="ball-clip-rotate" [fullScreen]="false">
    </ngx-spinner>
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="save()">{{ btnOkText }}</button>
</div>