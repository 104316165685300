import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../shared/services/auth.service';
import { last, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements AfterViewInit {

  isAuthenticating: boolean = true
  loadingText: string = '' 
  constructor(private _spinner: NgxSpinnerService, private _route: ActivatedRoute, private _router: Router, private _authService: AuthService) {}

  ngAfterViewInit(): void {
    this._authenticate()
  }

  private async _authenticate() {
    this._spinner.show('auth-spinner')
    this.loadingText = 'Checking authentication ...'
    const uuid = this._route.snapshot.queryParams['uuid']
    const sec = this._route.snapshot.queryParams['sec']
    if(!uuid) {
      this._router.navigate(['/unauthorized'], {
        replaceUrl: true
      })
    } else if(sec) {
      try {
        const token = (await lastValueFrom(this._authService.authHub(sec))).access_token
        sessionStorage.setItem('hub-token', token)
      } catch (err) {
        sessionStorage.removeItem('hub-token')
        this._router.navigate(['/unauthorized'], {
          replaceUrl: true
        })
        return
      }
    }
    const token = sessionStorage.getItem('hub-token')
    if(!token) {
      this._router.navigate(['/unauthorized'], {
        replaceUrl: true
      })
    } else {
      try {
        await lastValueFrom(this._authService.authProposer(token))
        this._authService.isAuthenticated = true
        this.loadingText = 'Moving to the Proposer ...'
        setTimeout(() => {
          this._router.navigate(['/proposer/recommendation'], {
            queryParams: {uuid: uuid},
            replaceUrl: true
          })
        }, 1000)
      } catch (err) {
        this._router.navigate(['/unauthorized'], {
          replaceUrl: true
        })
      }
    }
  }
}
