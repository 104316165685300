import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MODE_BUTTONS } from '../../../utils/items';
import packageInfo from 'package.json'
import { environment } from 'src/environments/environment';
import { DataService } from '../../../shared/services/data.service';
import { UserActionService } from 'src/app/shared/services/user-action.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  // propeties
  title = "Drawings"
  filterButtons = MODE_BUTTONS
  @Input() set currentFilter(value: number) {
    if(this._currentFilter === value) return;
    this._currentFilter = value;
    this.onFilterChoose(value);
  }
  @Input()  isDrawing: boolean = true;
  // output
  @Output() currentFilterChange: EventEmitter<number> = new EventEmitter<number>();
  fversion = packageInfo.version
  bversion!: string
  zoom: number = 100

  // private
  private _currentFilter!: number
  // event method
  constructor(private _dataService: DataService, private _userAction: UserActionService) {

  }

  ngOnInit(): void {
    this._dataService.getVersion().subscribe((val => { console.log(val);this.bversion = val }))
    this._userAction.zoomChange$.subscribe(val => {
      this.zoom = val;
    })
    this._userAction.titleChange$.subscribe(val => {
      this.title = val;
    })
  }

  onFitScreen() {
    this._userAction.announceFitScreen(true)
    this.zoom = 100
  }

  onFilterChoose(index: number): void {
    this.filterButtons.forEach((button, i) => {
      if(index === i) {
        button.isActive = true;
        return;
      }
      button.isActive = false;
    }); 
    this.currentFilterChange.emit(index);
  }
  // private method
}
