import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-treatment',
  templateUrl: './create-treatment.component.html',
  styleUrls: ['./create-treatment.component.css']
})
export class CreateTreatmentComponent {
  treatment!: string
  alertText!: string
  title!: string

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    if(!this.treatment) {
      this.alertText = 'Should not me empty!';
      return;
    }
    this.alertText = ''
    this.activeModal.close(this.treatment);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
