import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitInput'
})
export class UnitInputPipe implements PipeTransform {

  transform(value: number): number {
    let result: number = 0;
    if(value <= 20) result = value;
    else {
      result = 20 + (value - 20) * 2.5;
    }
    return result;
  }
}
