import { FaceSimulationService } from '../../services/face-simulation.service';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Event, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule, } from '@angular/material/slider';

import { FormsModule } from '@angular/forms';
import { log } from 'fabric/fabric-impl';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-face-simulation',
  templateUrl: './face-simulation.component.html',
  styleUrls: ['./face-simulation.component.css']
})
export class FaceSimulationComponent implements OnInit, AfterViewInit{
  message: string = '';
  url: string | ArrayBuffer | null = '';
  images: any;
  dataUrl: string | null | undefined = null;
  spin: boolean = false;
  private _mode: number = -1;
  onVertical: boolean = false;
  onHorizontal: boolean = false;
  onMesh: boolean = false;

  // private _sliderValue: number = 0;
  _upperVal: number = 0;
  _lowerVal: number = 0;
  _eyesBrowVal: number = 0;
  sliderVisible: boolean = false;
  upper: boolean = false;
  isEnabled = false;
  isAnalyzed = false;

  @Output() imageDataChange = new EventEmitter<string>();
  @ViewChild('targetImage') targetImage!: ElementRef;
  @ViewChild('sliderThumbUpper') sliderThumbUpper!: ElementRef;
  @ViewChild('sliderThumbLower') sliderThumbLower!: ElementRef;
  @ViewChild('sliderForEyesBrow') sliderForEyesBrow!: ElementRef;
  @ViewChild('outputDiv') outputDiv!: ElementRef;
  @ViewChild('canvasOutput') canvas!: ElementRef<HTMLCanvasElement>;



  constructor(
    private _faceSimulationService: FaceSimulationService, private _userActionService: UserActionService,
    private _spinner: NgxSpinnerService, private _modalService: ModalService
  ) {
    
  }
  ngOnChanges(changes: any): void{

  }
  ngOnInit(): void {
    this._userActionService.replaceImage$.subscribe((value) => {
      this._replaceImage();
    });
    this. _userActionService.faceImage$.subscribe(data => {
      if(this.isAnalyzed) return;
      this._spinner.show("face-load")
      this.targetImage.nativeElement.onload = () => {
        this._faceSimulationService.uploadImageToCanvas().then((status) => {
          this._spinner.hide("face-load")
          switch(status) {
            case -1:
              this._modalService.openNotification('Wait for Application to load before clicking!').finally(() => {
                this._userActionService.announceBackDrawings(0);
              })
              break;
            case -2:
              this._modalService.openNotification('Unable to detect face. Please try again with a different image. FACE Simulation will be disabled for this session!')
                .finally(() => {
                  this._userActionService.announceLockFace(0);
                  this._userActionService.announceBackDrawings(0);
                });
              break;
            default: 
              this.isEnabled = true;
              this.isAnalyzed = true;
          }
        });
      }
      this.url = data;
      this.sliderVisible = true;
    });
  }
  async ngAfterViewInit(): Promise<void> {
  }

  async onVerticalClick() {
    this.spin = true
    if (this._mode === 2) {
      this._mode = 0;
    }
    this.turnOff()

    this.onVertical = !this.onVertical;
    if(!this.onVertical){
      this.turnOff()
      this.spin = false;
      return
    }
    // await this._faceSimulationService.uploadImageToCanvas()
    this._faceSimulationService.showVerticalDividers(this._mode, this.onVertical);
    this._mode = this._mode === 1 ? 0 : 1;
    this.spin = false
    this.onHorizontal = false
    this.onMesh = false
  }

  async onHorizontalClick() {
    this.spin = true
    if (this._mode === 1) {
      this._mode = 0;
    }
    this.turnOff()

    this.onHorizontal = !this.onHorizontal;
    if(!this.onHorizontal){
      this.turnOff()
      this.spin = false;
      return
    }
    this._faceSimulationService.showHorizontalDividers(this._mode, this.onHorizontal);
    this._mode = this._mode === 2 ? 0 : 2;
    this.spin = false
    this.onMesh = false
    this.onVertical = false
  }


  async onFileChanged(event: any) {
    // this._faceSimulationService.removeAllCanvas(this.targetImage.nativeElement);
    // this._faceSimulationService.resetCtx2DCanvas();
    this.spin = true
    this._mode = -1;
    const files = event.target.files;
    if (!files || files.length === 0) {
      this.spin = false;
      return;
    }

    // đang cho đọc nhiều files ??
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.images = files;
    reader.readAsDataURL(files[0]);
    this.targetImage.nativeElement.onload = () => {
      this._faceSimulationService.uploadImageToCanvas().then(() => console.log("Done"));
    }
    reader.onload = (_event) => {
      this.url = reader.result;
      // console.log(`đây là targetImage.nativeElement của ảnh: ${this.url}`)
    };
    this.sliderVisible = true;
    this.spin = false
    const image = new Image();
  }

  onDownload() {
    this.dataUrl = this._faceSimulationService.downloadCanvas(
      this.targetImage.nativeElement
    );
  }

  async onDrawFaceMesh() {
    this.spin = true;
    this.onMesh = !this.onMesh;
    if(!this.onMesh){
      this.turnOff()
      this.spin = false;
      return
    }
    await this._faceSimulationService
      .drawFaceMesh(this.targetImage.nativeElement, this._mode, this.onMesh)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    this.onVertical = false
    this.onHorizontal = false  
  }


  // Xử lý môi
  async onLiftLips(upper: boolean) {
    this.spin = true;
    this.sliderVisible = true && this.url != '';
    this.upper = upper;
    console.log("if it's called here again")
    await this._faceSimulationService
      .updatePreState(upper)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, upper ? this._upperVal : this._lowerVal, upper)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.stack))
      .finally(() => (this.spin = false));
  }

  async onValueChangedUpper() {

    this.spin = true
    console.log('value uppler lip' + this._upperVal)
    await this._faceSimulationService
      .updatePreState(true)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, this._upperVal, true)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }

  async onValueChangedLower() {

    this.spin = true
    await this._faceSimulationService
      .updatePreState(false)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    await this._faceSimulationService
      .liftLips(this.targetImage.nativeElement, this._lowerVal, false)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }


 
  // Xử lý lông mày
  async onLiftEyesBrow() {
    this.spin = true;
    this.sliderVisible = true && this.url != '';

    await this._faceSimulationService
      .liftEyesBrow(this._eyesBrowVal)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }

  async onValueChangedForEyesBrow() {
    this.spin = true

    await this._faceSimulationService
      .liftEyesBrow(this._eyesBrowVal)
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
  }


  formatSliderValue() {
    return 'Custom Value' // hoặc bất kỳ định dạng nào phù hợp với bạn
  }

  async resetImg(){
    this.spin = true
    await this._faceSimulationService
      .resetImg()
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));
    this._lowerVal = 0
    this._eyesBrowVal = 0
    this._upperVal = 0
    this.onVertical = false
    this.onHorizontal = false 
    this.onMesh = false
  }
  async resetImgForLifting(){
    this.spin = true
    await this._faceSimulationService
      .resetImg()
      .then(() => console.log('Success!'))
      .catch((error: Error) => console.log(error.message))
      .finally(() => (this.spin = false));

  }
  async turnOff(){
    this.spin = true;
    this._faceSimulationService.turnOffUpperCanvas()
  }

  // private methods
  private _replaceImage() {
    const {lowerUrl, upperUrl, imageWidth, imageHeight} = this._faceSimulationService.getImageFromCanvas();
    if(upperUrl == null) {
      this.imageDataChange.emit(lowerUrl)
      this._spinner.hide('filter-spinner');
      return;
    }
    const tempCanvas = document.createElement('canvas')
    tempCanvas.setAttribute('width', `${imageWidth}px`)
    tempCanvas.setAttribute('height',`${imageHeight}px`)
    // document.body.appendChild(tempCanvas)
    const tempCtx = tempCanvas.getContext('2d')
    const lowerImage = new Image();
    const upperImage = new Image();
    upperImage.onload = () => {
      tempCtx!.drawImage(upperImage, 0, 0)
      const url = tempCanvas.toDataURL('image/jpg')
      this.imageDataChange.emit(url)
      // tempCanvas.remove()
    }
    lowerImage.onload = () => {
      tempCtx!.drawImage(lowerImage, 0, 0, lowerImage.naturalWidth, lowerImage.naturalHeight)
      upperImage.src = upperUrl
    }
    lowerImage.src = lowerUrl
  }
}
