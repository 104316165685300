import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ImageService } from '../../shared/services/image.service';
import { EMPTY, Observable, lastValueFrom } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { ProposerComponent } from '../proposer.component';

export const ProposerGuardCanActivate = async (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService)
  const router = inject(Router)
  if(!route.queryParams['uuid'] ) {
    return false
  } 
  if(authService.isAuthenticated) return true
  else {
    await router.navigate(['/proposer'], {
      queryParams: route.queryParams,
      replaceUrl: true
    })
    return false
  }
}


