import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { FilesetResolver, ImageSegmenter, MPMask } from '@mediapipe/tasks-vision';
import cv, { Mat, Scalar } from 'opencv-ts';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  private readonly _bgColor: Scalar = cv.Scalar.all(255)
  private readonly _modelPath = './assets/models/bgrm.tflite'

  private _imageSegmenter!: ImageSegmenter

  constructor() {
  }

  private async _createImageSegmenter() {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
    );
    this._imageSegmenter = await ImageSegmenter.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath:
          this._modelPath,
        delegate: 'CPU'
      },
      outputCategoryMask: true,
      outputConfidenceMasks: false,
      runningMode: 'IMAGE'
    });
  }

  showMemory() {
    let m: any = window.performance
    console.log(m.memory)
  }

  segment(image: HTMLImageElement) {
    const original = cv.imread(image)
    const rs = original.clone()
    try {
      const result = this._imageSegmenter.segment(image);
      const masks = result.categoryMask as MPMask
      let mask = cv.matFromArray(masks.height, masks.width, cv.CV_8UC1, Array.from(masks.getAsUint8Array()))
      var binary_mask = new cv.Mat(mask.cols, mask.rows, mask.type())
      cv.threshold(mask, binary_mask, 0, 255, cv.THRESH_BINARY_INV)
      rs.setTo(this._bgColor, binary_mask)
      mask.delete()
      binary_mask.delete()
      masks.close()
      result.close()
      return rs
    } catch(err: any) {
      rs.delete()
      throw new Error('Error ' + err.message)
    } finally {
      original.delete()
    }
  }
}
