<div class="flex flex-col w-full grow bg-black text-white">
  <div class="flex grow w-full gap-2 px-5">
    <!-- side bar -->
    <div class="w-44 shrink-0 flex flex-col justify-between text-white h-full">
      <!-- <app-treatment-bar></app-treatment-bar> -->
      <div></div>
      <div class="flex flex-col items-center">
        <!-- 3 slider -->
        <label>Upper Lip's : {{ _upperVal * 5 }}</label>
        <div>
          <div class="flex flex-col items-center gap-4">
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_upperVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-upper-invisible"
                [disabled]="!sliderVisible"
                name="upperLipSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _upperVal }}"
                  #sliderThumbUpper
                  (valueChange)="onValueChangedUpper()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <label>Lower Lip : {{ _lowerVal * 5 }}</label>
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_lowerVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-lower-invisible"
                [disabled]="!sliderVisible"
                name="lowerLipSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _lowerVal }}"
                  #sliderThumbLower
                  (valueChange)="onValueChangedLower()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <label>Eyebrows : {{ _eyesBrowVal * 5 }}</label>
            <div class="flex w-fit items-center gap-4">
              <span>0</span>
              <mat-slider
                [(ngModel)]="_eyesBrowVal"
                min="0"
                max="1"
                step="0.2"
                tickInterval="0.2"
                [displayWith]="formatSliderValue"
                id="slider-for-eyesBrow"
                [disabled]="!sliderVisible"
                name="eyesBrowSlider"
                ngDefaultControl
                style="width: 100px"
              >
                <input
                  matSliderThumb
                  value="{{ _eyesBrowVal }}"
                  #sliderForEyesBrow
                  (valueChange)="onValueChangedForEyesBrow()"
                />
              </mat-slider>
              <span>5</span>
            </div>
          </div>
        </div>
        <!-- 3slider -->
        <div class="flex gap-4 pt-5">
          <div
            [ngClass]="
              (onVertical ? 'active' : '') +
              ' flex flex-col items-center p-1 rounded gap-1 cursor-pointer hover:bg-ffred'
            "
            (click)="onVerticalClick()"
          >
            <img
              src="../../../../assets/img/svg/vertical.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">Vertical</p>
          </div>
          <div
            [ngClass]="
              (onHorizontal ? 'active' : '') +
              ' flex flex-col items-center p-1 rounded gap-1 cursor-pointer hover:bg-ffred'
            "
            (click)="onHorizontalClick()"
          >
            <img
              src="../../../../assets/img/svg/horizontal.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">Horizontal</p>
          </div>
          <div
            class="flex flex-col items-center gap-1 p-1 rounded cursor-pointer hover:bg-ffred"
            (click)="resetImg()"
          >
            <img
              src="../../../../assets/img/svg/refresh.svg"
              alt=""
              class="w-4"
            />
            <p class="text-xs">Reset</p>
          </div>
        </div>
      </div>
    </div>
    <!-- side bar -->

    <!-- content -->
    <div class="w-full h-full flex flex-col px-18 gap-2">
      <div class="flex m-0 p-2 text-white items-center">
        <div class="w-full flex flex-wrap gap-2 justify-between" role="group"
            aria-label="Basic radio toggle button group">
            <div class="cursor-pointer w-full h-9 max-w-40 xl:max-w-60 py-2 rounded-lg text-center">
            </div>
        </div>
      </div>
      <div class="bg-ffgray2 border-2 rounded-lg border-white w-full h-full flex gap-4">
        <div class="w-full h-full flex-col gap-2">
          <div
            id="faceImageCanvasContainer"
            class="relative w-full h-full flex justify-center items-center overflow-hidden"
          >
            <img
              style="object-fit: contain"
              #targetImage
              src="{{ url }}"
              crossorigin="anonymous"
              id="face-image"
              class="absolute max-h-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="fps" class="hidden"></div>
  <!-- footer -->
  <div></div>
  <!-- <input
      hidden
      type="file"
      #fileInput
      multiple
      (change)="onFileChanged($event)"
    />
    <button mat-raised-button id="upload-file" (click)="fileInput.click()">
      Upload File
    </button> -->
</div>
<ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="face-load" size="medium" type="ball-clip-rotate" [fullScreen]="true">
  <p style="font-size: 30px; font-weight: bold;">Analyzing...</p>
</ngx-spinner>
<div class="fixed right-0 left-0 top-0 bottom-0 bg-ffblack z-[100]" *ngIf="!isEnabled"></div>
