<div class="flex px-5 text-white text-center font-semibold">
    <div class="flex items-center justify-between w-full">
        <ng-container *ngIf="isDrawing">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onSaveToHub()">BACK TO HUB</div>
            <div class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onToRecommendation()">PROCEED TO RECOMMENDATION</div>
        </ng-container>
        <ng-container *ngIf="isRecommend">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onToDrawings()">BACK TO DRAWING TOOLS</div>
            <div *ngIf="!isPrint" class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onMoveToPrint()">PROCEED TO REPORTING</div>
        </ng-container>
        <ng-container *ngIf="isFace">
            <div class="px-3 bg-black rounded-lg py-2" style="cursor: pointer;" (click)="onToDrawings()">BACK TO DRAWING TOOLS</div>
            <div *ngIf="!isPrint" class="px-3 bg-ffred rounded-lg py-2" style="cursor: pointer;" (click)="onSaveToDrawings()">SAVE TO DRAWING TOOLS</div>
        </ng-container>
    </div>
</div>