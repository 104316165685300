export class DrawingConstant {
    // Filter strength
    public static REDNESS_STRENGTH: number = 1.6
    public static EVENESS_STRENGTH: number = 1.0
    public static UV_STRENGTH: number = 0.25

    // Canvas
    public static MAX_ZOOM = 50
    public static TARGET_FIND_TOLERANCE = 20
    public static BACKGROUND_CROP_COLOR = 'rgba(255,0,0,0.3)'

    // Product
    public static DEFAULT_COLOR = '#893681'
    public static DEFAULT_FONTSIZE = 40
    public static DEFAULT_LINEWIDTH = 5
    public static DEFAULT_HLWIDTH = 40
    public static DEFAULT_HLOPACITY = 0.5
    public static DEFAULT_INJECTIONS_RADIUS = 10

}