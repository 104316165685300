<div class="flex flex-col h-full w-full rounded-lg py-2 gap-3">
    <div class="flex px-2 text-sm items-center">
        <div>Treatments</div>
        <div class="flex ml-auto gap-1">
            <i class="fa-solid fa-arrows-rotate cursor-pointer" (click)="refresh()"></i>
            <i class="fa-sharp fa-solid fa-pencil cursor-pointer" (click)="onToggleTreatmentEditor()"></i>
            <i class="fa-solid fa-sharp fa-plus cursor-pointer" (click)="onAddTreatment()"></i>
        </div>
    </div>
    <div class="flex flex-col gap-2 px-2 text-xs h-full overflow-y-auto relative">
        <div [draggable]="!isTreatmentEditorShown" class="flex gap-2 items-center bg-ffgray1 rounded p-2" *ngFor="let treatment of treatmentList;"
            (dragstart)="onTreatmentDragStart($event, treatment.name)">
            <i matTooltip="Click to edit" matTooltipPosition="above" matTooltipShowDelay="200" class="fa-sharp fa-solid fa-pencil" style="cursor: pointer;" *ngIf="!isTreatmentEditorShown" (click)="onClickTreatment($event, treatment)"></i>
            <i matTooltip="Click to delete" matTooltipPosition="above" matTooltipShowDelay="200" class="fa-solid fa-xmark" style="cursor: pointer;" *ngIf="isTreatmentEditorShown" (click)="onDelTreatment($event, treatment)"></i>
            <div class="flex w-full cursor-grab">{{treatment.name}}</div>
        </div>
        <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" name="tm-spinner" size="medium" type="ball-clip-rotate" [fullScreen]="false">
        </ngx-spinner>
    </div>
</div>