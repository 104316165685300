import { AfterViewInit, Component, EventEmitter, Output, Input, ViewChild, ElementRef, OnDestroy, Injectable, OnInit, ViewChildren } from '@angular/core';
import { COLOR_PICKER, FONT_SIZES, HIGHLIGHT_OPACITY, HIGHLIGHT_SIZES, LINE_SIZES, MODE_BUTTONS, TOOLBAR_ITEMS } from '../../../utils/items';
import { CanvasComponent } from './canvas/canvas.component';
import { UnitInputPipe } from '../../pipes/unit-pipe/unit-input.pipe';
import { MlInputPipe } from '../../pipes/ml-pipe/ml-input.pipe';
import { ImageFilterService } from '../../../shared/services/image-filter.service';
import cv, { Mat } from 'opencv-ts';
import cloneDeep from 'lodash.clonedeep';
import { HubImage } from '../../../shared/services/image.service';
import { ActivatedRoute } from '@angular/router';
import { TreatmentBarComponent } from './treatment-bar/treatment-bar.component';
import { DataService } from '../../../shared/services/data.service';
import { last, lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../../shared/services/modal.service';
import { DrawingConstant } from 'src/app/utils/const';
import { BackgroundService } from 'src/app/shared/services/background.service';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { eventListeners } from '@popperjs/core';


@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css'],
})
export class ImageEditorComponent implements AfterViewInit, OnInit, OnDestroy {

  // constructor
  constructor(private _modal: ModalService, private bgService: BackgroundService, private _spinner: NgxSpinnerService, 
    private _route: ActivatedRoute, private unitPipe: UnitInputPipe, private mlPipe: MlInputPipe, 
    private imageFilterService: ImageFilterService, private dataService: DataService,
    private _userActionService: UserActionService) 
  {
    this.unitsValues = Array(33).fill(0).map((x, i) => i);
    this.mlValues = Array(24).fill(0).map((x, i) => i);
  }
  ngOnInit(): void {
    this._userActionService.lockFace$.subscribe((v) => this.isFaceEnabled = false)
  }
  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    lastValueFrom(this.dataService.getSettings())
      .then((result) => {
        this.drawingColor = result.color || DrawingConstant.DEFAULT_COLOR
        this.fontSize = result.fontSize || DrawingConstant.DEFAULT_FONTSIZE
        this.lineWidth = result.lineWidth || DrawingConstant.DEFAULT_LINEWIDTH
        this.highlightOpacity = result.highlightOpacity || DrawingConstant.DEFAULT_HLOPACITY
        this.highlightWidth = result.highlightWidth || DrawingConstant.DEFAULT_HLWIDTH
        this.injectionsRadius = result.injectionsRadius || DrawingConstant.DEFAULT_INJECTIONS_RADIUS
      }).catch((err) => {
      });
    this.onChangeUnit();
    this.onSelectTool(null, -1, -1);
    this.canvas.selectMode(-1);
  }

  // public method
  getCroppedImage(): string {
    return this.canvas.exportImage();
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  // event method

  onSliderMouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onSliderTouchStart(ev: TouchEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onContainerTouchEnd(ev: TouchEvent): void {
    //ev.preventDefault();
    this._isSliding = false;
  }

  onContainerTouchMove(ev: TouchEvent): void {
    if (!this._isSliding) return;
    if (ev.touches.length > 1) return;
    let offsetX = ev.touches[0].clientX - this.canvasBounds.nativeElement.offsetLeft;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / this.canvasBounds.nativeElement.offsetWidth : 0;
    if (this.sliderLeft > 100) this.sliderLeft = 100
    this._sliderEv = ev;
    if (this._filterMode > 0)
      this.canvas.modifyOverlayImage(ev);
    ev.stopPropagation();
  }

  onContainerMouseUp(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseLeave(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseMove(ev: any): void {
    if (!this._isSliding) return;
    let rect = ev.currentTarget.getBoundingClientRect();
    let offsetX = ev.clientX - rect.left;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / rect.width : 0;
    this._sliderEv = ev;
    if (this._filterMode > 0)
      this.canvas.modifyOverlayImage(ev);
  }

  onSelectTool(ev: any, mode: number, index: number): void {
    if(ev) {
      const isActive = ev.currentTarget.classList.contains('bg-ffred');
      document.querySelectorAll('.item.selectable').forEach((e) => {
        e.classList.remove('bg-ffred');
      });
      if(!isActive) ev.currentTarget.classList.add('bg-ffred');
    }
    let value = 0;
    this.isCrossOrPoint = false;
    this.toolbarItems.forEach((itemGroups, i) => {
      itemGroups.forEach((item, j) => {
        if (i === mode && j === index) {
          item.isActive = !item.isActive;
          if (item.isActive) {
            if (value == 10 || value == 11) this.isCrossOrPoint = true;
            this.canvas.selectMode(value);
          } else {
            this.canvas.selectMode(-1);
          }
        }
        else item.isActive = false;
        value++;
      })
    })
  }

  onToggleSettingsTab(value: boolean) {
    this.isSettingsTabShown = value;
  }

  saveSettings() {
    lastValueFrom(this.dataService.saveSettings(this.drawingColor, this.lineWidth, this.fontSize, this.highlightWidth, this.highlightOpacity, this.injectionsRadius))
      .then((result) => {
      }).catch((err) => {
      });
  }

  onChangeUnit() {
    if (this.unit) {
      this.canvas.unit = 'ml';
      this.onChangeMlValue(this.currentMlValue);
    }
    else {
      this.canvas.unit = 'units';
      this.onChangeUnitValue(this.currentUnitValue);
    }
  }

  onChangeUnitValue(value: number) {
    this.canvas.value = this.unitPipe.transform(this.currentUnitValue);
  }

  onChangeMlValue(value: number) {
    this.canvas.value = this.mlPipe.transform(this.currentMlValue);
  }

  onFaceSimulation(ev: Event) {
    this.faceSimulation.emit();
    this._userActionService.announceTitleChanged("Face Simulation");
  }
  // Logic methods

  hasChanged() {
    return this.canvas && this.canvas.hasChanged()
  }

  onUnRedo(value: number) {
    if (value == 0) this.canvas.undoCanvas();
    else this.canvas.redoCanvas();
  }

  onUncrop(ev: Event) {
    this.canvas.uncrop()
  }

  onTriggerDelete() {
    document.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 46 }));
  }

  async onToggleBackground(value: boolean) {
    if (!value) {
      this._spinner.show('filter-spinner')
      if (this.bgImage) {
        this.canvas.changeBackground(this.bgImage)
        this.hasBackground = value
      } else {
        try {
          this.bgImage = (await lastValueFrom(this.dataService.removeBackground(this.uuid, this._imageData))).url
          this.canvas.changeBackground(this.bgImage)
          this.hasBackground = value
        } catch (err: any) {
          this._modal.openNotification('Some error has appear.')
        }
      }

    } else {
      this._spinner.show('filter-spinner')
      this.canvas.changeBackground(this._imageData)
      this.hasBackground = value
    }

  }

  onSelectLineSize(e: any, size: number, index: number) {
    this.lineSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.lineSize = color.width;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectFontSize(e: any, size: number, index: number) {
    this.fontSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.fontSize = color.size;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectHighlightSize(e: any, size: number, index: number) {
    this.highlightSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.highlightSize = color.width;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectHighlightOpacity(e: any, size: number, index: number) {
    this.highlightOpacityChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.highlightOpacity = color.opacity;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectColor(e: any, index: number) {
    this.colorChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.drawingColor = color.color;
      }
    })
    this.canvas.resetBrush();
  }

  onRulerFlip(ev: MouseEvent) {
    this.ruler.isTop = !this.ruler.isTop;
    if (!this.ruler.isTop)
      this.ruler.top = 'calc(100% - 100px)';
    else this.ruler.top = '10px'
  }

  onChangeFilterMode(ev: MouseEvent, mode: number) {
    if (!this._imageData) {
      this._modal.openNotification('Please wait until the image is ready!');
      return;
    }
    if (mode) {
      this._spinner.show('filter-spinner')
    }
    this._filterMode = mode;
    this.filterModeChange.emit(mode);
    this._filterImage(mode);
  }
  onFileChanged(event: any) {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    // đang cho đọc nhiều files ??
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (_event) => {
      this._userActionService.announceUploadFaceImage(reader.result as string)

      // console.log(`đây là targetImage.nativeElement của ảnh: ${this.url}`)
    };
    reader.readAsDataURL(files[0]);

    
    
  }

  // private method
  private _filterImage(mode: number) {
    switch (mode) {
      case 0:
        this.canvas?.removeOverlayImage();
        break;
      case 3:
        if (this._imageFilterURL.evenness) {
          this.canvas.setOverlayImage(this._imageFilterURL.evenness, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(() => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            let rs = this.imageFilterService.filterEvenness(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.evenness = this.canvasFilter.nativeElement.toDataURL('image/jpeg');
            this.canvas.setOverlayImage(this._imageFilterURL.evenness, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification('Some error appear. Please try again');
            this.filterModeChange.emit(0)
          }
        }, 100);
        break;
      case 2:
        if (this._imageFilterURL.redness) {
          this.canvas.setOverlayImage(this._imageFilterURL.redness, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(() => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            let rs = this.imageFilterService.filterRedness(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.redness = this.canvasFilter.nativeElement.toDataURL('image/jpeg');
            this.canvas.setOverlayImage(this._imageFilterURL.redness, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification('Some error appear. Please try again');
            this.filterModeChange.emit(0)
          }
        }, 100);
        break;
      case 1:
        if (this._imageFilterURL.uv) {
          this.canvas.setOverlayImage(this._imageFilterURL.uv, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(() => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            let rs = this.imageFilterService.filterUv(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.uv = this.canvasFilter.nativeElement.toDataURL('image/jpeg');
            this.canvas.setOverlayImage(this._imageFilterURL.uv, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification('Some error appear. Please try again');
            this.filterModeChange.emit(0)
          }
        }, 100)
    }
  }

  private _resetFilter() {
    this._imageFilterURL = {
      evenness: null,
      uv: null,
      redness: null
    };
    if (this._bgr) this._bgr.delete();
    this._bgr = null;
  }



  // private attributes
  private _imageData!: string
  private _filterMode: number = 0
  private _bgr!: Mat | null
  private _isSliding: boolean = false
  private _imageFilterURL!: {
    redness: string | null
    evenness: string | null
    uv: string | null
  }
  private _sliderEv!: MouseEvent | TouchEvent
  private _lastMlValue: number = 0
  private _lastUnitValue: number = 0
  private _url: string | ArrayBuffer | null = '';

  //static
  // properties 
  isSettingsTabShown: boolean = false;
  filterButtons = MODE_BUTTONS
  toolbarItems = cloneDeep(TOOLBAR_ITEMS);
  lineSizeChoices = cloneDeep(LINE_SIZES);
  fontSizeChoices = cloneDeep(FONT_SIZES);
  highlightSizeChoices = cloneDeep(HIGHLIGHT_SIZES);
  highlightOpacityChoices = cloneDeep(HIGHLIGHT_OPACITY);
  colorChoices = cloneDeep(COLOR_PICKER);
  isLineSizeAdditional = false;
  isFontSizeAdditional = false;
  unitsValues!: number[]
  mlValues!: number[]
  isUnits: boolean = true
  unit: number = 0
  isLoading: boolean = true
  loadingText: string = 'Loading image...'
  isError: boolean = false
  isCrossOrPoint: boolean = false
  sliderLeft: number = 50
  currentUnitValue: number = 0
  currentMlValue: number = 0
  ruler = {
    top: 'calc(100% - 100px)',
    isTop: false
  };
  bgImage!: string
  hasBackground: boolean = true
  lineWidth: number = DrawingConstant.DEFAULT_LINEWIDTH
  fontSize: number = DrawingConstant.DEFAULT_FONTSIZE
  highlightWidth: number = DrawingConstant.DEFAULT_HLWIDTH
  drawingColor: string = DrawingConstant.DEFAULT_COLOR
  highlightOpacity: number = DrawingConstant.DEFAULT_HLOPACITY
  injectionsRadius: number = DrawingConstant.DEFAULT_INJECTIONS_RADIUS
  isImageLoaded: boolean = false
  isFaceEnabled = true;
  @Input() uuid!: string

  // output
  @Output() retry: EventEmitter<null> = new EventEmitter()
  @Output() filterModeChange: EventEmitter<number> = new EventEmitter();
  @Output() faceSimulation: EventEmitter<null> = new EventEmitter();
  // input

  @Input() set imageData(value: string) {
    if (!value) return;
    this._imageData = this.imgFilter.nativeElement.src = value;
    this._resetFilter();
    this.canvas.removeOverlayImage();
    this.canvas.browseImage(this._imageData, this.canvasBounds.nativeElement.clientHeight, this.canvasBounds.nativeElement.clientWidth);
    this.filterModeChange.emit(0);
  }

  get filterMode(): number {
    return this._filterMode;
  }

  set filterMode(mode) {
    if (!this._imageData) {
      this._modal.openNotification('Please wait until the image is ready!');
      return;
    }
    if (mode) {
      this._spinner.show('filter-spinner')
    }
    this._filterMode = mode;
    this.filterModeChange.emit(mode);
    this._filterImage(mode);  
  }

  // viewchild
  @ViewChild('canvas')
  canvas!: CanvasComponent;
  @ViewChild('imgFilter')
  imgFilter!: ElementRef<HTMLImageElement>
  @ViewChild('canvasFilter')
  canvasFilter!: ElementRef<HTMLCanvasElement>
  @ViewChild('canvasBound')
  canvasBounds!: ElementRef<HTMLDivElement>
}
