import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserActionService {

    // Observable string sources
    private _imageFitSource = new Subject<boolean>();
    private _zoomChangeSource = new Subject<number>();
    private _titleChangeSource = new Subject<string>();
    private _replaceImageSource = new Subject<boolean>();
    private _faceImageSource = new Subject<string>();
    private _uploadFaceImageSource = new Subject<string>();
    private _lockFaceSource = new Subject<number>();
    private _backDrawingsSource = new Subject<number>();
  
    // Observable string streams
    imageFit$ = this._imageFitSource.asObservable();
    zoomChange$ = this._zoomChangeSource.asObservable();
    titleChange$ = this._titleChangeSource.asObservable();
    replaceImage$ = this._replaceImageSource.asObservable();
    faceImage$ = this._faceImageSource.asObservable();
    uploadFaceImageSource$ = this._uploadFaceImageSource.asObservable();
    lockFace$ = this._lockFaceSource.asObservable();
    backDrawings$ = this._backDrawingsSource.asObservable();

  constructor() { }

  announceFitScreen(value: boolean) {
    this._imageFitSource.next(value);
  }

  announceZoomChanged(value: number) {
    this._zoomChangeSource.next(value);
  }

  announceTitleChanged(value: string) {
    this._titleChangeSource.next(value);
  }

  announceReplaceImage(value: boolean) {
    this._replaceImageSource.next(value);
  }

  announceFaceImage(value: string) {
    this._faceImageSource.next(value);
  }
  announceUploadFaceImage(value: string) {
    this._uploadFaceImageSource.next(value);
  }

  announceLockFace(value: number) {
    this._lockFaceSource.next(value);
  }

  announceBackDrawings(value: number) {
    this._backDrawingsSource.next(value);
  }
}
