import { Injectable } from '@angular/core';
import cv, { Mat, MatVector } from 'opencv-ts';
import { DrawingConstant } from '../../utils/const';

@Injectable({
  providedIn: 'root'
})
export class ImageFilterService {

  constructor() { }

  logMemory() {
    let m: any = window.performance;
    console.log(m.memory);
  }

  filterUv(bgr: Mat): Mat {
    try {
      let gray = this.uvRgb2Gray(bgr, DrawingConstant.UV_STRENGTH);
      return gray;
    } catch(err) {
      console.log(err);
    }
    return new cv.Mat();
  } 


  filterEvenness(bgr: Mat): Mat {
    try {
      let gray = new cv.Mat(bgr.rows, bgr.cols, cv.CV_8UC1);
      this.evennessRgb2Gray(bgr, gray, DrawingConstant.EVENESS_STRENGTH);
      let rs = bgr.clone();
      rs.setTo(new cv.Scalar(0), gray);
      gray.delete();
      return rs;
    } catch(err) {
      console.log(err);
    }
    return new cv.Mat();
  }

  filterRedness(bgr: Mat): Mat {
    try {
      let gray: Mat = new cv.Mat(bgr.rows, bgr.cols, cv.CV_8UC1);
      this.redRgb2Gray(bgr, gray);
      let h: Mat = new cv.Mat(gray.rows, gray.cols, cv.CV_8UC1, new cv.Scalar(0));
      let v: Mat = new cv.Mat(gray.rows, gray.cols, cv.CV_8UC1, new cv.Scalar(240));
      let s: Mat = gray.clone();
      gray.delete();
      let rs = bgr.clone();
      this.utLNStretchExp8(s, DrawingConstant.REDNESS_STRENGTH, 32, 255);
      let mv: MatVector = new cv.MatVector();
      mv.push_back(h);
      mv.push_back(s);
      mv.push_back(v);
      cv.merge(mv, rs);
      h.delete();
      s.delete();
      v.delete();
      mv.delete();
      cv.cvtColor(rs, rs, cv.COLOR_HSV2RGB);
      return rs;
    } catch (err) {
      console.log(err);   
    }
    return new cv.Mat();
  }

  private uvRgb2Gray(bgr: Mat, strength: number): Mat {
    let mv: MatVector = new cv.MatVector();
    cv.split(bgr, mv);
    let gray0: Mat = mv.get(2);
    let gray1: Mat = mv.get(1);
    let gray = mv.get(0);
    this.utFilterDOG(gray0, gray, 1, 512, 0.25, 3);
    this.utFilterDOG(gray0, gray1, 1, 128, 0.25, 3);
    cv.addWeighted(gray1, strength, gray, 1 - strength, 0, gray);
    gray0.delete();
    gray1.delete();
    mv.delete();
    return gray;
  }

  private redRgb2Gray(bgr: Mat, gray: Mat): void {
    try {
      let c3: Mat = new cv.Mat();
      bgr.convertTo(c3, cv.CV_32FC3, 1/255);
      let mv: MatVector = new cv.MatVector();
      cv.split(c3, mv);
      c3.delete();
      let r32f: Mat = mv.get(0);
      let g32f: Mat = mv.get(1);
      let w: number = gray.rows, h: number = gray.cols;
      let p_r32 = r32f.data32F;
      let p_g32 = g32f.data32F;
      let p = gray.data;
      let cr: number, severity: number;
      for(let y = 0; y < h; y++) {
        for(let x = 0; x < w; x++) {
          cr = (p_r32[x+y*w]>0.2 ? (p_r32[x+y*w] - 0.2) / 0.8 : 0);
          severity = 255 * (1 - p_g32[x+y*w]) * (1 - p_g32[x+y*w]) * (1 - p_g32[x+y*w]) * 5 * cr;
          p[x+y*w] = severity > 255? 255 : (severity < 0? 0 : severity);
        }
      };
      r32f.delete();
      g32f.delete()
      mv.delete();
    } catch (err) {
      console.log(err)
    }
  }

  private evennessRgb2Gray(bgr: Mat, gray: Mat, strength: number): void {
    cv.cvtColor(bgr, gray, cv.COLOR_RGB2GRAY);
    this.utFilterDOG(gray, gray, 1, 8 * 2, 0.5, strength);
    gray.convertTo(gray, gray.type(), 2);
    cv.threshold(gray, gray, 240, 255, cv.THRESH_BINARY_INV);
  }

  private utFilterDOG(src: Mat, dst: Mat, gr1: number, gr2: number, acc: number, contrast: number): void {
    let src1 = new cv.Mat(src.rows, src.cols, cv.CV_16SC1);
    let src2 = new cv.Mat(src.rows, src.cols, cv.CV_16SC1);
    let src2_8u = new cv.Mat(src.rows, src.cols, src.type());
    src.convertTo(src1, src1.type());
    cv.GaussianBlur(src1, src1, new cv.Size(gr1*2+1, gr1*2+1), 0, 0, 0);
    this.utFilterGaussFast(src, src2_8u, gr2, acc);
    src2_8u.convertTo(src2, src2.type());
    cv.addWeighted(src1, 1, src2, -1, 0, src1);
    src1.convertTo(dst, dst.type(), contrast, 127);
    src1.delete();
    src2.delete();
    src2_8u.delete();
  }

  private utFilterGaussFast(src: Mat, dst: Mat, r: number, acc: number) {
    let small: Mat = new cv.Mat(src.rows * acc, src.cols * acc, src.type());
    cv.resize(src, small, small.size());
    cv.GaussianBlur(small, small, new cv.Size(Math.floor(r*acc)*2+1, Math.floor(r*acc)*2+1), 0, 0, 0);
    cv.resize(small, dst, dst.size())
    small.delete();
  }

  private utLNStretchExp8(gray: Mat, pow: number, minv: number, maxv: number): void {
    try {
      let gray32f: Mat = new cv.Mat(gray.rows, gray.cols, cv.CV_32FC1);
      let sub: Mat = new cv.Mat(gray.rows, gray.cols, gray.type(), new cv.Scalar(minv));
      cv.subtract(gray, sub, gray);
      gray.convertTo(gray32f, gray32f.type(), 1.0/(maxv-minv));
      cv.pow(gray32f, pow, gray32f);
      gray32f.convertTo(gray, gray.type(), 255);
      sub.delete();
      gray32f.delete();
    } catch (err) {
      console.log('ERROR utLNS')
    }
  }
}
