import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from '../../../../shared/services/data.service';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Form } from '@angular/forms';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { Product } from 'src/app/proposer/models/product';

const URL = "";

@Component({
  selector: 'app-create-product-model',
  templateUrl: './create-product-modal.component.html',
  styleUrls: ['./create-product-modal.component.css']
})

export class CreateProductModalComponent {
  @Input() title: string = 'CREATE PRODUCT'
  @Input() btnCancelText: string = 'Cancel'
  @Input() btnOkText: string = 'Save'
  @Input() category!: string
  
  product!: Product
  name!: string
  description!: string
  price1!: number
  price1desc!: string
  price2!: number
  price2desc!: string
  image!: File
  alert!: string
  isProcessing: boolean = false
  constructor(private activeModal: NgbActiveModal, private _dataService: DataService, private _spinner: NgxSpinnerService) {

  }

  onImageSelected(event: any) {
    this.image = event.target.files.length == 1? event.target.files[0] : null;
  }

  public decline() {
    this.activeModal.close();
  }

  public save() {
    this.alert = ''
    if(!this._validate()) {
      this.alert = 'Required fields not filled'
      return;
    }
    this._spinner.show('modal-spinner')
    this.isProcessing = true;
    let fd: FormData = new FormData();
    fd.append('file', this.image);
    fd.append('category', this.category);
    fd.append('name', this.name);
    fd.append('description', this.description? this.description : '');
    fd.append('price1', this.price1? this.price1.toString() : '0');
    fd.append('price1desc', this.price1desc? this.price1desc : '');
    fd.append('price2', this.price2? this.price2.toString() : '0');
    fd.append('price2desc', this.price2desc? this.price2desc : '')
    lastValueFrom(this.product? this._dataService.putProduct(fd, this.product._id):this._dataService.createProduct(fd))
      .then(res => {
        this.isProcessing = false;
        this.activeModal.close(res.payload.product);
      }).catch(err => {
        this.isProcessing = false
      }).finally(() => {
        this._spinner.hide('modal-spinner')
      })
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  private _validate() {
    if(!this.name || (!this.image && !this.product)) {
      return false;
    }
    return true;
  }

  private _showError(statusText: string) {
    this.alert = statusText;
  }
}
