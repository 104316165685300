import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent {

  category!: string
  alertText!: string

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    if(!this.category) {
      this.alertText = 'Should not me empty!';
      return;
    }
    this.alertText = ''
    this.activeModal.close(this.category);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
