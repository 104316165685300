import { AfterViewChecked, AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { HubImage, ImageService } from '../shared/services/image.service';
import { HttpClient } from '@angular/common/http';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { Observable, Subscription, lastValueFrom, reduce, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { DataDelete } from './models/data-delete';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../shared/services/modal.service';
import { environment } from 'src/environments/environment';
import { UserActionService } from '../shared/services/user-action.service';

@Component({
  selector: 'app-proposer',
  templateUrl: './proposer.component.html',
  styleUrls: ['./proposer.component.css']
})
export class ProposerComponent implements AfterViewInit, OnDestroy, OnInit {
  imageData!: string
  uuid!: string
  croppedImageData!: string
  currentFilter: number = 0
  isPrint: boolean = false
  isRecommend: boolean = false
  isFace: boolean = false
  isLoading: boolean = false
  loadingText: string = ''
  reloadFlag: boolean = false
  returnPath: string = 'doctor/linked_devices/proposer_return'

  private _originalImage!: string

  @ViewChild('imageEditor') imageEditor!: ImageEditorComponent

  constructor(private spinner: NgxSpinnerService, private _modalService: ModalService, 
    private _location: Location, private _router: Router, private _imageService: ImageService, 
    private _route: ActivatedRoute, private _userActionService: UserActionService) {

  }
  ngOnInit(): void {
    this._userActionService.uploadFaceImageSource$.subscribe((value) => {
      this._originalImage = this.imageData = value
    });
  }
  ngOnDestroy(): void {
  }
  ngAfterViewInit(): void {
    this._getImage()
  }

  // event method
  async onSaveToHub() {
    let token = sessionStorage.getItem('hub-token');
    if (!token) {
      this._router.navigate(['unauthorized']);
      return;
    }
    if (!this.imageEditor.hasChanged()) {
      return window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${this.uuid}`);
    }
    else {
      const res = await this._modalService.openConfirm('CONFIRM', 'Do you want to save the edited image?', 'Yes', 'No')
      if (res) {
        this.spinner.show('save-spinner')
        this.croppedImageData = this.imageEditor.getCroppedImage();
        let blob = await (await fetch(this.croppedImageData)).blob();
        let file: File = new File([blob], 'editedImage', { type: blob.type });
        this._imageService.uploadImage(token, file, this._image)
          .then((res) => {
            window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${res.uuid}`);
          }).catch((err) => {
              this._modalService.openNotification('Server Error. Please try again')
          }).finally(() => {
            this.spinner.hide('save-spinner')
          });
      } else {
        window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${this.uuid}`);
      }
    }
  }

  onReloadImage() {
    this._getImage();
  }

  reloadComponent(data: boolean) {
    this.reloadFlag = data;
  }

  onPrintClosed(): void {
    this.isPrint = false;
  }

  onFaceSimulation() {
    this._userActionService.announceFaceImage(this._originalImage);
    this.isFace = true;
    this._isDrawing = false;
    this.isRecommend = false;
  }
  // Private 
  private _image!: HubImage;
  public get image(): HubImage {
    return this._image;
  }
  public set image(value: HubImage) {
    this._image = value;
  }

  private _isDrawing: boolean = true;
  public get isDrawing(): boolean {
    return this._isDrawing;
  }
  public set isDrawing(value: boolean) {
    this._isDrawing = value;
    if (!value) {
      this.spinner.show('move-spinner')
      setTimeout(() => {
        this.croppedImageData = this.imageEditor.getCroppedImage();
        this.spinner.hide('move-spinner')
      }, 100);
    }
  }

  private _getImage() {
    this.spinner.show('filter-spinner')
    lastValueFrom(this._imageService.getImage(sessionStorage.getItem('hub-token') || '', this._route.snapshot.queryParams['uuid']))
      .then(res => {
        this.image = res
        this.uuid = this._image.uuid
        this._originalImage = this.imageData = this._image.file_url
        this.imageEditor.isError = false
      })
      .catch(err => {
        this.spinner.hide('filter-spinner')
        switch (err.status) {
          case 403: {
            this._modalService.openNotification('The session has expired! Please close open the Proposer again from the Hub.')
            break;
          }
          case 404: {
            this._modalService.openNotification('Image not found! Please choose an existing image.')
            break;
          }
          default: {
            this._modalService.openNotification('Unknown error. Please try again.')
            this.imageEditor.isError = true
            break;
          }
        }
      })
  }
}
