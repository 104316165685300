<div class="flex px-5 font-semibold">
    <div class="w-40 py-3 border-b-4 border-ffred text-center">{{title}}</div>
</div>
<ngx-spinner 
    color="rgba(137,54,129,1)"
    bdColor="rgba(51,51,51,1)"
    type="square-jelly-box"
    [fullScreen]="true"
    name="move-spinner"
>
    <p style="font-size: 30px;">Moving to the Recommendation...</p>
</ngx-spinner>
<ngx-spinner 
    color="rgba(137,54,129,1)"
    bdColor="rgba(51,51,51,0.8)"
    type="ball-clip-rotate"
    [fullScreen]="true"
    name="save-spinner"
>
    <p style="font-size: 30px;">Saving image...</p>
</ngx-spinner>
